import { Tab } from "@headlessui/react";

import { FC, Fragment, useState, useEffect } from "react";

import Label from "components/Label/Label";

import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useReservationStore, useAuthStore } from "hooks";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";



export interface CheckOutPagePageMainProps {
  className?: string;
}



const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  // eslint-disable-next-line
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [formState, setFormState] = useState({ url: '', Ds_SignatureVersion: '', Ds_MerchantParameters: '', Ds_Signature: '' });
  const [orderPaymentState, setOrderPayment] = useState({} as any);
  const { status, user } = useAuthStore();
  const { car, createPayment, createSignatureForm, cleanCar } = useReservationStore();
  const [totalInvoice, setTotalInvoice] = useState<number>(0);
  const [gestionCost, setGestionCost] = useState("0.00");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usernote,setusernote] = useState('');

  const handlePayment = async () => {
    setIsSubmitting(true);  // Deshabilitar el botón al iniciar el proceso
    const totalInvoice = calcularTotal();
    setTotalInvoice(totalInvoice);
    console.log("car", car);
    console.log("usernote",usernote);
    /* Se quita la imagen para enviar para crear la reserva */
    const carNoImage = car.map(({ srcImage, ...rest }: { srcImage: string, [key: string]: any }) => rest);

    const dataPayment = await createPayment(totalInvoice, gestionCost, carNoImage, user.uid, orderPaymentState, usernote);
  
    cleanCar();

    //Recibe del servidor que ya ha sido reservado el slot
    console.log("----------Resultado CreatePayment", dataPayment);
    if (!dataPayment.ok) {
      alert(dataPayment.msg);
      console.log("Error al crear el dataPayment", dataPayment.msg);

      return;
    }
    // Enviar el formulario manualmente
    const form = document.getElementById("formPayment") as HTMLFormElement;
    form.submit();
  }


  const createFormPayment = async () => {

    const totalInvoice = calcularTotal();
    setTotalInvoice(totalInvoice);
    const dataPayment = await createSignatureForm(totalInvoice);
    const { form, orderPayment } = dataPayment.object;

    setOrderPayment(orderPayment);
    console.log("orderPayment", orderPayment)
    setFormState({ url: form.url, Ds_SignatureVersion: form.body.Ds_SignatureVersion, Ds_MerchantParameters: form.body.Ds_MerchantParameters, Ds_Signature: form.body.Ds_Signature });


  }
 

  const formatDate = (fechaOriginal: Date) => {
    // Crear un objeto Date a partir de la cadena de fecha original
    const fecha = new Date(fechaOriginal);

    // Obtener el día, el mes y el año de la fecha
    const dia = fecha.getDate();
    const mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el nombre del mes en español
    const año = fecha.getFullYear();

    // Formatear la fecha en el formato deseado
    return `${dia} de ${mes} de ${año}`;
  }



  const calcularTotal = () => {
    let total = 0;

    if (car.length > 0) {
      car.forEach((item: any) => {
        const precio =
          typeof item.price === "string"
            ? parseFloat(item.price.replace(",", "."))
            : item.price;
        total += precio;
      });
      setGestionCost((total * 5 / 100).toFixed(2));
      total = total + (total * 5) / 100;
    }
    return parseFloat(total.toFixed(2));
  };
  useEffect(() => {
    const total = calcularTotal();


    setTotalInvoice(total);
    //setGestionCost((total * 5 / 100).toFixed(2));
  }, [car]);

  useEffect(() => {
    createFormPayment();
  }, []);

  const renderSidebar = () => {
    return (
      <div className="w-full hidden md:flex flex-col rounded-2xl border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 p-6 sm:p-6 xl:p-8 mb-8">

        {car.length > 0 && car.map((item: any, index: number) => {
          // code for rendering car items


          return (
            <>
              <div
                key={index}
                className="flex flex-col sm:flex-row sm:items-center"
              >
                <div className="flex-shrink-0 w-full sm:w-40">
                  <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                    <img
                      alt=""
                      className="absolute inset-0 object-cover"
                      sizes="200px"
                      src={item.srcImage}
                    />
                  </div>
                </div>
                <div className="py-5 sm:px-5 space-y-3">
                  <div>
                    <p className="text-2xl font-bold line-clamp-1 text-slate-100">
                      {item.roomName}
                    </p>
                    <span className="text-base font-bold mt-1 block text-slate-100">
                      {" "}
                      {formatDate(item.dateReservation)}

                    </span>
                    <span className="text-neutral-400 block text-md mt-1">
                      {item.start} - {item.end}
                    </span>
                  </div>
                  <span className="text-gray-400 text-md mt-1">
                    Reserva para {item.capacidadMin} personas
                  </span>
                  <br />
                  <span className="text-gray-400 text-md mt-1">
                    Persona adicional {item.pricePerson}€/persona. Si sois más la
                    diferencia se paga en el local
                  </span>
                  <br />
                  <span className="text-gray-400 text-md mt-1">
                    Capacidad Max: {item.capacidad} personas.
                  </span>{" "}
                </div>
              </div>

              <div className="flex flex-col space-y-4">
                <h3 className="text-2xl font-semibold">Detalle precio</h3>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{item.capacidadMin} personas</span>
                  <span>
                    {(
                      parseFloat(item.price.replace(",", "."))

                    ).toFixed(2) + '€'}
                  </span>
                </div>

              </div>
            </>
          );
        })}
        <div className="flex justify-between font-semibol text-1xl  mb-4 text-slate-200">
          <span>Gasto de Gestión</span>
          <span>{gestionCost} {'€'}</span>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between font-semibol text-2xl  mb-4 text-slate-200">
          <span>Total</span>
          <span>{totalInvoice} {'€'}</span>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    const { name } = user;
    return (
      <div className="w-full flex flex-col rounded-2xl border border-neutral-200 dark:border-neutral-700 space-y-8 p-6 sm:p-6 xl:p-8 mb-8">
        <h2 className="text-3xl text-white lg:text-4xl font-semibold">
          Confirmar y pagar

        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div></div>

        <div>


          <div className="mt-2">
            {
              (status === "not-authenticated") ? (
                <>
                  <Tab.Group>
                    <Tab.List className="flex my-5 gap-1">
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button
                            className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${selected
                              ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                              : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                              }`}
                          >
                            <span className="mr-2.5">Tengo usuario 😎</span>
                            {/* <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      /> */}
                          </button>
                        )}
                      </Tab>
                      {<Tab as={Fragment}>
                        {({ selected }) => (
                          <button
                            className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${selected
                              ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                              : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                              }`}
                          >
                            <span className="mr-2.5">No tengo usuario 🚀🚀</span>
                            {/* <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      /> */}
                          </button>
                        )}
                      </Tab>}
                    </Tab.List>

                    <Tab.Panels>
                      <Tab.Panel className="space-y-5">
                        <PageLogin />
                        {/* <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" placeholder="example@karaokebarcelona.com" defaultValue="" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" placeholder="*******" defaultValue="" />
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div> */}
                      </Tab.Panel>
                      <Tab.Panel className="space-y-5">
                        {/* <div className="space-y-1">
                    <Label>Nro de tarjeta </Label>
                    <Input defaultValue="111 112 222 999" />
                  </div> */}
                        <PageSignUp />
                        {/* <div className="space-y-1">
                    <Label>Nombre </Label>
                    <Input 
                    placeholder="FREDY MERCURY" 
                    defaultValue="" />
                  </div>
                  
                      <div className="space-y-1">
                    <Label>Email </Label>
                    <Input 
                    type="email" 
                    placeholder="example@karaokebarcelona.com" 
                    defaultValue=""
                     />
                  
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>Teléfono </Label>
                      <Input
                       type="phone" 
                       placeholder="663567890" 
                       defaultValue=""  />
                    </div>
                  
                  <div className="space-y-1">
                    <Label>Mensaje </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div> */}
                      </Tab.Panel>



                    </Tab.Panels>
                  </Tab.Group>
                </>
              ) : (
                <>
                  <h3 className="text-2xl font-semibold mb-4 text-slate-200">🎶🎶  Ya casi estas listo  {name} para cantar  💃 🕺</h3>
                  <div className="space-y-1">
                    <Label>¿Tienes algún pedido especial? </Label>
                    <Textarea 
                    placeholder="..." 
                    id="usernote"
                    value={usernote}
                    onChange={(e) => setusernote(e.target.value)}
                    />
                    <span className="text-sm text-neutral-500 block">
                      Coméntanos en que podemos ayudarte
                    </span>
                  </div>
                  <div className="pt-8">


                    <form id="formPayment" name="formPayment" action={`${formState.url}`} method="POST">
                      <input type="hidden" id="Ds_SignatureVersion" name="Ds_SignatureVersion" value={`${formState.Ds_SignatureVersion}`} />
                      <input type="hidden" id="Ds_MerchantParameters" name="Ds_MerchantParameters" value={`${formState.Ds_MerchantParameters}`} />
                      <input type="hidden" id="Ds_Signature" name="Ds_Signature" value={`${formState.Ds_Signature}`} />

                      <ButtonPrimary type="button" onClick={handlePayment} disabled={isSubmitting}>
                        Confirmar y pagar
                      </ButtonPrimary>

                    </form>



                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className=" flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
